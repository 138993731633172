<template>
    <div>
      <b-alert variant="danger" show>Page not found!<br><br><router-link to="/"><b-button variant="primary">Go Home</b-button></router-link></b-alert>
    </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>