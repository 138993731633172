<template>
  <div>
      <div class="content">
          <div class="container">
              <h1>Generate Url</h1>
              <b-form class="form" @submit.prevent>
                  <b-input-group class="mt-3">
                      <b-form-input 
                      v-model="val"
                      placeholder="Enter URL"
                      :state="inputState !== null ? inputState : null"
                      @input="validateURL(val)"
                      ></b-form-input>
                  </b-input-group>    
                  <b-form-checkbox v-model="form.confirmationRequired" @change="validateURL(val)" plain>
                      Warning "you are about to leave" message
                  </b-form-checkbox>
                  <div v-if="form.confirmationRequired === true">
                    <b-input-group class="mt-3">
                        <b-form-input placeholder="Page name" v-model="confpagename" @input="validateURL(val)"></b-form-input>
                    </b-input-group>
                  </div>  
              </b-form>
              <a v-if="copyState" @click="copyToClipboard" style="text-decoration: none; cursor: pointer">
                  <b-card class="mt-3">
                      <div class="copy">
                          <i class="fa-solid fa-copy"></i>
                          <span>{{ fOutput }}</span>
                      </div>
                  </b-card>
              </a>
          </div>
      </div>
  </div>
</template>


<script>
import Swal from 'sweetalert2'

export default {
  name: 'GenerateComp',
  data() {
    return {
      val: '',
      inputState: null,
      copyState: false,
      genLink: '',
      confpagename: '',
      form: {
        confirmationRequired: false
      }
    }
  },
  methods: {
    validateURL(url) {
      if (!url) {
        this.inputState = null;
        this.genLink = '';
        this.copyState = false;
        return;
      }
      try {
        new URL(url);
        this.inputState = true;
        let confirmationParam = this.form.confirmationRequired ? 'true' : 'false';
        let confpagename = this.confpagename ? this.confpagename : encodeURIComponent('Previous page');
        this.genLink = `https://qlink.daxel.pl/url?href=${encodeURIComponent(url)}&confirmation=${confirmationParam}&confpagename=${confpagename}`;
        this.copyState = true;
      } catch (e) {
        this.inputState = false;
        this.genLink = '';
        this.copyState = false;
      }
    },
    copyToClipboard() {
      if (this.genLink) {
        navigator.clipboard.writeText(this.genLink).then(() => {
          Swal.fire("Success!", "URL copied to clipboard", "success");
        }).catch(() => {
          Swal.fire("Error", "Failed to copy URL", "error");
        });
      }
    },
  },
  computed: {
    fOutput() {
      return this.genLink.substring(0, 40) + '...'; 
    }
  }
}
</script>



<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #95ddff;
    font-family: 'Montserrat', sans-serif;
}

.container {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: auto;
    gap: 10px;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.copy {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-size: 1.2rem;
    border-radius: 5px;
    flex-direction: row;
    text-decoration: none;
}
</style>
