<template>
  <div v-if="url" class="bg">
    <div class="container" v-if="state === 'go'">
      <div class="head">
        <b-spinner></b-spinner>
        <h2>Redirecting...</h2>
      </div>
      <p>to <b>{{ urlFormatted }}</b></p>
      <p>If you are not redirected automatically,</p>
      <b-button variant="primary" @click="go">Click here</b-button>
    </div>
    <div class="container" v-else-if="state === 'confirmation'">
      <div class="head">
        <h2>Wait!</h2>
      </div>
      <p>You are about to leave <b>{{ confirmationPageName }}</b></p>
      <p>and going to <b>{{ urlFormatted }}</b></p>
      <p>Are you sure?</p>
      <div class="buttons">
        <b-button variant="primary" @click="state = 'go'">Continue</b-button>
        <b-button variant="outline-primary" @click="goBack">Go back</b-button>
      </div>
    </div>
  </div>
  <div v-else>
    <b-alert variant="danger" show>Invalid URL!</b-alert>
  </div>
</template>

<script>
export default {
  name: 'RedirectorComp',
  data() {
    return {
      url: this.validateURL(this.$route.query.href),
      maxLength: 20,
      confirmation: Boolean(this.$route.query.confirmation), // Bezpieczniejsze rzutowanie
      confirmationPageName: this.$route.query.confpagename || 'Previous page', // Uproszczone
      state: null
    };
  },
  watch: {
    state() {
      if (this.state === 'go') {
        setTimeout(this.go, 2000); // Lepsze odniesienie do metody
      }
    }
  },
  methods: {
    go() {
      if (this.url) {
        window.location.href = this.url; // Użyj window.location zamiast location dla jasności
      }
    },
    validateURL(url) {
      try {
        new URL(url);
        return url;
      } catch (e) {
        return null;
      }
    },
    goBack() {
      window.history.back();
    }
  },
  mounted() {
    this.state = this.confirmation ? 'confirmation' : 'go'
    if (this.url && this.state === 'go') {
      setTimeout(this.go, 2000); // Lepsze odniesienie do metody
    }
  },
  computed: {
    urlFormatted() {
      if (this.url) {
        return this.url.length > this.maxLength ? this.url.substring(0, this.maxLength) + '...' : this.url;
      }
      return '';
    }
  }
}
</script>


<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

  .buttons {
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: center;
  }

  .head {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    flex-direction: column;
  }

  .bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #95ddff;
  }

  .container {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 400px;
  }
</style>
