import Vue from 'vue';
import Router from 'vue-router';
import RedirectorComp from '../components/RedirectorComp.vue';
import HomeComp from '../components/HomeComp.vue';
import NotFound from '../components/NotFound.vue';
import GenerateComp from '../components/GenerateComp.vue';

 
Vue.use(Router);

const routes = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeComp,
      meta: { title: 'QLink' }
    },
    {
      path: '/url',
      name: 'url',
      component: RedirectorComp,
      meta: { title: 'Redirecting...' }
    },
    {
      path: '*',
      name: '404',
      component: NotFound,
      meta: { title: '404!!' }
    },
    {
      path: '/generate',
      name: 'generate',
      component: GenerateComp,
      meta: { title: 'Generate URL' }
    },
  ]
});

routes.afterEach((to) => {
  // Uzyskaj tytuł z metadanych trasy docelowej
  const pageTitle = to.meta.title;

  // Ustaw tytuł strony, jeśli jest dostępny
  if (pageTitle) {
    document.title = pageTitle;
  }
});


export default routes;