<template>
    <div>
        QLink v1.0.0
        <br>
        Generate link <router-link to="/generate">here</router-link>
    </div>
</template>

<script>
export default {
  name: 'HomeComp'
}
</script>